import React, { useState, useRef } from 'react';
import useCollapse from 'react-collapsed';
import Layout from 'components/layout';
import SEO from 'components/seo';
import matchResultImage from 'images/match-results.png';
import DesktopLogo from '../../assets/desktop-logo.inline.svg';

const questions = [
  {
    title: 'Who are Maistro?',
    answer:
      "When it comes to buying services, Maistro is a brand new model. A smart system that makes it easy and fast for our clients to buy better. With more control and visibility. We're a procurement software service provider harnessing cutting edge technology and data analytics to lead the way in tail spend management. A key component to this vision is having access to great suppliers, this is where you come in. At Maistro we pride ourselves in surfacing new innovative suppliers and championing fresh talent that's relevant to our clients needs.",
  },
  {
    title: 'What is the onboarding process?',
    answer:
      "It's an invite only platform to guarantee high vetting principles and quality to both buyer and supplier. A member of the Maistro team will review your submission to join and contact you if we think you're a good fit for the platform. We will take some details over the phone and send out an invite to the platform where you can activate you accounts – that's it! You will then appear in matching results for tenders. We do ask that you complete our vetted information to ensure we have the details we need from you to pass our vetted checks. This is crucial to ensure you're getting sent the very best opportunities to match your services.",
  },
  {
    title: 'What is the tendering process?',
    answer:
      "If you appear in the matching results for our buyers and they invite you to tender, an email notification will be sent to you asking for you to submit a tender for the work. To respond to the tender, log into the platform to see the tender project brief and follow the steps. Once you're happy with your response you can submit. You will be notified if you have been successful in your submission via email and from the platform dashboard.",
  },
  {
    title: 'What happens if I have a problem?',
    answer:
      'Our supplier relationship team are always on hand to help support you with your tendering submissions and any platform questions. You can also download our useful guide whilst logged into the platform.',
  },
  {
    title: 'Are there any costs involved?',
    answer:
      'Our platform is completely free to suppliers, by signing up you are in with the chance to be matched with some amazing tender opportunities from our ever growing customer data base.',
  },
  {
    title: 'Who are your customers/buyers?',
    answer:
      'We have recently made some significant updates to our platform, along with attracting a number of new and well-known brands looking for services in sectors such as Business and Professional Services, Facilities Management, IT and Marketing to name a few.',
  },
  {
    title: 'What happens to my data?',
    answer:
      'We only ask for your data once, this is to set you up on our system. Please see our Privacy Policy for more details. All data collected by Maistro is transmitted over secure connections to Azure SQL database located behind access controlled firewalls and is encrypted at rest. Data is then backed up to secure geo-redundant storage within the Azure environment.',
  },
  {
    title: 'How many other suppliers will I be up against?',
    answer:
      'All tenders or requests for quote are now invite only. This will limit the number of other vendors being asked to respond. Because we are frontloading the task of getting all supplier information, certification and skillsets, those running the tender will already have a good idea about the set of suppliers they are seeking, rather than casting a wide net to unknown suppliers.',
  },
];

const Question = ({ question, answer }) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`,
    );
  }

  return (
    <div>
      <button
        type="button"
        className="py-6 appearance-none focus:outline-none border-b border-secondary w-full text-left"
        onClick={toggleAccordion}
      >
        <h4 className="uppercase flex">
          <span className="w-8 text-primary block ">+</span>
          <span className="flex-1">{question}</span>
        </h4>
      </button>
      <div
        className="overflow-hidden duration-200"
        style={{ maxHeight: `${setHeight}`, transition: '0.2s all' }}
        ref={content}
      >
        <div className="bg-secondary text-black p-10">{answer}</div>
      </div>
    </div>
  );
};

const Features = ({
  title, description, image, content,
}) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <>
      <div className="container">
        <div className="grid grid-cols-layout isMd:grid-cols-1 mb-8">
          <div className="w-28 isMd:my-0 isMd:mx-auto  isMd:mb-12 ">
            <img src={`/images/${image}`} alt={title} className="w-full" />
          </div>
          <div className="pl-8">
            <div className="mb-6">
              <h3 className="font-bold normal-case">{title}</h3>
            </div>
            <div className="mb-4">
              <p className="text-primary">{description}</p>
            </div>
            <div
              className="flex items-center cursor-pointer"
              {...getToggleProps()}
            >
              <div className={`pr-2 w-6 ${isExpanded ? 'rotate mt-2' : ''}`}>
                <img
                  src="/images/expand-icon.png"
                  alt="expand-icon"
                  className="w-full h-full"
                />
              </div>
              <div>
                <p className="text-primary">
                  {isExpanded ? 'Collapse' : 'Expand'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="mb-8" style={{ backgroundColor: 'rgb(219, 228, 235)' }}>
          <div className="container">
            <div className="flex p-6 gap-12 flex-col md:flex-row">
              <div className="flex items-center w-full">
                <p className="text-primary">{content?.description}</p>
              </div>
              <div className="flex items-center">
                <img
                  src={`/images/${content?.image}`}
                  alt={title}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Platform = () => (
  <Layout>
    <SEO
      title="Procurement Software"
      description="The Maistro platform is a powerful Tail Spend Management tool allowing you to source providers, tender contracts and capture spend data"
    />
    <div className="bg-stone py-12">
      <div className="container">
        <div className="grid grid-cols-1 xl:grid-cols-2 items-center gap-6 p-6">
          <div className="flex gap-4 flex-col ">
            <h1 className="block font-bold ">
              Intelligent Tail Spend Management Platform
            </h1>
            <h3>Delivers autonomy with control</h3>

            <div className="text-primary normal-case font-normal">
              From requisition to award, your users are guided through the best
              way to buy a service, whilst the system captures data in a way
              that was previously not possible.
            </div>

            <h5 className="text-primary normal-case text-2xl font-bold">
              Until now.
            </h5>
          </div>

          <img
            src={matchResultImage}
            alt="Maistro Platform Screenshot"
            className="w-full"
          />
        </div>
      </div>
    </div>

    <section className="bg-white py-12">
      <div className="container">
        <div className="flex items-center justify-center isMd:flex-col isMd:justify-center">
          <img
            src="/images/50_ Graphic.png"
            alt="50_ Graphic"
            className="w-full p-6 mb-12 md:mb-0"
            style={{ maxWidth: 300 }}
          />
          <div className="md:text-right w-7/12 isMd:w-full">
            <h5 className="text-primary normal-case text-2xl font-normal">
              Buying in services is complex and time consuming:
            </h5>
            <ul className="pl-6 mt-4 list-none text-primary">
              <li>
                - identifying, vetting & on-boarding potential service providers
              </li>
              <li>- translating needs and wants into supplier deliverables</li>
              <li>- assessing, scoring and short-listing bid responses</li>
              <li>- long drawn-out price and contract negotiations</li>
            </ul>
            <p className="mt-4 font-bold text-primary">
              We take that headache away
            </p>
          </div>
        </div>
      </div>
    </section>

    <div
      id="features"
      className="py-6"
      style={{ backgroundColor: 'rgb(219, 228, 235)' }}
    >
      <div className="container">
        <div className="flex items-center justify-center flex-wrap">
          <div className="w-44">
            <DesktopLogo />
            {/* <img
              src="/images/desktop-logo.inline.svg"
              alt="podium"
              className="w-full h-full"
            /> */}
          </div>
          <div className="px-4 tab:px-0" />
          <div className="isMd:mt-8 isMd:text-center">
            <h3 className="normal-case">
              intelligent tail spend management platform
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div className="py-12">
      <Features
        title="Requirement Builder"
        description="Whether sourcing a low-value repeat service or high-value rolling contract, the Requirement Builder sets you up for success with speed."
        image="project_builder_icon.png"
        content={{
          description:
            'RFIs, RFPs, and RFQs each provide value, but it is when they come together that businesses truly gain value overall. Users can submit any or all of them to suppliers in order to understand the different capabilities and services available to them and at what cost. The business can use these data points and incorporate them into their larger procurement goals',
          image: 'RequirementBuilder.png',
        }}
      />
      <Features
        title="Profile Builder"
        description="Supplier skills & capabilities are automatically converted into a structured profile to maximise potential for matching with the most suitable provider"
        image="skills_builder_icon.png"
        content={{
          description:
            "Leveraging our supplier profiles, users can filter and search for keywords or services within the profile meaning they can cast a wide net, but also quickly narrow the list to see only vendors that meet their criteria, ensuring that suppliers don't spend time answering an RFP they are unlikely to win.",
          image: 'ProfileBuilder.png',
        }}
      />
      <Features
        title="Response Builder"
        description="Quote and tender responses automatically convert into detailed, easy to score submissions."
        image="response_builder_icon.png"
        content={{
          description:
            'Supplier responses can be quickly and easily created. The Response Builder auto-populates data from the Supplier Profile to answer any like for like questions. This saves both time and effort providing a simple centralised store of standard qualification and tender information which can then be further enhanced for each opportunity.',
          image: 'ResponseBuilder.jpg',
        }}
      />
      <Features
        title="Shortlist Builder"
        description="Evaluation models automatically assess bid submissions in terms of quality, cost and compliance"
        image="shortlist_builder_icon.png"
        content={{
          description:
            "The Shortlist Builder allows users to specify parameters and weigh various factors in the RFx. By establishing requirements upfront, users spend time thinking about the project, what's most important to them, and what to look for in the responses. Once they have entered parameters and weights of different data fields in the RFx, the software then rates each supplier response and sorts them based on overall scores. ",
          image: 'ShortlistBuilder.png',
        }}
      />
      <Features
        title="Contract Builder"
        description="Requirements, responses and prices seamlessly convert to a Statement of Works with your standard T & C\'s built in."
        image="contract_builder_icon.png"
        content={{
          description:
            "Our Contract Builder enables your legal team to set rules and guidelines. In this way, there's no need to involve Legal in every transaction. From the RFx data, users can create compliant Statements of Work quickly and error-free, regardless of length or complexity. Since the solution is online, you always ensure every team uses the latest version of your T&C's.",
          image: 'builder/05 - Contract builder.png',
        }}
      />
      <Features
        title="Insight Builder"
        description="Dashboards automatically monitor progress while data enables faster, better decisions."
        image="insight_builder_icon.png"
        content={{
          description:
            'Real-time dashboards monitor the progress of sourcing events and have filters to look at each area through spend and supplier category as well as user and service delivery location criteria. Enhanced modules such as Opportunity Assessment enable you to create initiatives across your entire tail spend portfolio, to drive further savings from supplier consolidation and re-negotiation. ',
          image: 'InsightBuilder.png',
        }}
      />
    </div>

    <div className="py-12 bg-secondary">
      <div className="container w-9/12 isMd:w-full">
        <h3 className="text-center normal-case">
          <span className="text-primary font-bold">Powerful</span> procurement
          tools designed to be used by{' '}
          <span className="text-primary font-bold">anyone</span> in your
          workforce
        </h3>
      </div>
    </div>

    <div className="bg-stone" id="FAQs">
      <div className="container py-20">
        <h1 className="text-black mb-4 normal-case">FAQs</h1>
        {questions.map((q) => (
          <Question key={q.title} question={q.title} answer={q.answer} />
        ))}
      </div>
    </div>
  </Layout>
);

export default Platform;
